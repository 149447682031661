var StringMask = require('string-mask');

export const unMaskCpf = (input) => {
    if (!input) {
        return input;
    }
    let rgx = /[.-]/;
    return input.replace(new RegExp(rgx.source, 'g'), '');
};

export const maskCpf = (input) => {

    if (!input) {
        return input;
    }
    let rgx = /[.-]/;
    var cleanInput = input.replace(new RegExp(rgx.source, 'g'), '');
    var formatter = new StringMask('000.000.000-00');

    var result = formatter.apply(cleanInput); // 129.658.156-20

    if (result[result.length - 1] == '.' || result[result.length - 1] == '-') {
        return result.replace(/.$/, '');
    } else {
        return result;
    }


};


export const unMaskDate = (input) => {
    if (!input) {
        return input;
    }
    let rgx = /[\/]/;
    return input.replace(new RegExp(rgx.source, 'g'), '');
};

export const maskDate = (input) => {

    if (!input) {
        return input;
    }

    var cleanInput = input.replace(/\D/g,'')
    let formatter = null;
    let result = input

    let searchElement = cleanInput.length;
    let number = [3,5,8].indexOf(searchElement);
    if(number>-1) {
        formatter = new StringMask('00/00/0000');
    }

    if(formatter){
        result = formatter.apply(cleanInput);
    }
    return result;

};

export const maskCep = (input) => {

    if (!input) {
        return input;
    }
    let cleanInput = input.replace(/\D/g,'')
    let formatter = null;
    let result = input

    if(cleanInput.length>5){
        formatter = new StringMask('00000-000');
    }

    if(formatter){
        result = formatter.apply(cleanInput);
    }

    return result;

};

export const maskPhone = (input) => {


    if (!input) {
        return '';
    }

    let pattern = /[()]/;
    var cleanInput = input.replace(new RegExp(pattern.source, 'g'), '');
    var formatter = new StringMask('(00)000000000');

    var result = formatter.apply(cleanInput); // 129.658.156-20

    if (result[result.length - 1] == '(' || result[result.length - 1] == ')') {
        return result.replace(/.$/, '');
    } else {
        return result;
    }

};

export const unMaskPhone = (input) => {

    let pattern = /[()]/;
    return input.replace(new RegExp(pattern.source, 'g'), '');

};

export const unMaskCep = (input) => {
    let pattern = /-/;
    return input.replace(new RegExp(pattern.source, 'g'), '');
};
