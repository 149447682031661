const {getEndpointUrl} = require("./utils.service");


exports.getValorFrete = async (cep) => {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return new Promise((resolve, reject) => {

        let endpointUrl = getEndpointUrl();
        fetch(endpointUrl+'/api/produto/calcular-valor-frete/'+cep, requestOptions)
            .then(response => {
                if(response.ok){
                    return response.json()
                }else{
                    reject(response)
                }

            })
            .then(data => {
                resolve(data)
            } )
            .catch(err=> reject(err));
    })

}

exports.loadCep = async (cep) => {

    return new Promise(async (resolve, reject) => {

        if(cep && cep.replace(/\D/g,'').length===8){

            let replace = cep.replace(/\D/g,'');
            try {
                const res = await fetch(`https://viacep.com.br/ws/${replace}/json`)
                if (!res.ok) {
                    //throw new Error(`Response failed`)
                    reject()
                }
                resolve(await res.json());
            } catch (error) {

            }
        }else{
            reject();
        }



    });

}
