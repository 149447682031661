import {getJWTToken, setCliente, setJWTToken} from "./auth";
import {objectToURLQuery} from "./utils.service";

export const isBrowser = () => typeof window !== "undefined"
const getEndpointUrl = require('./utils.service').getEndpointUrl

const ClienteService = {

    async getEnderecos(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getJWTToken()
            },
        };

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+'/api/cliente/all-enderecos', requestOptions)
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }else{
                        reject(response)
                    }

                })
                .then(data => {
                    resolve(data)
                } )
                .catch(err=> reject(err));
        });
    },
    async getPedidos(limit=100,offset=0){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getJWTToken()
            },
        };

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            let queryParameters = objectToURLQuery({limit,offset});
            fetch(endpointUrl+'/api/cliente/meus-pedidos?'+queryParameters, requestOptions)
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }else{
                        reject(response)
                    }

                })
                .then(data => {
                    resolve(data)
                } )
                .catch(err=> reject(err));
        });
    },
    async excluirEndereco(endereco){
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getJWTToken()
            },
            body: JSON.stringify(endereco)
        };

        let url = '/api/cliente/remover-endereco/'+endereco.id;

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+ url, requestOptions)
                .then(response => {
                    if(response.ok){
                        resolve()
                    }else{
                        reject(response)
                    }

                })
                .catch(err=> reject(err));
        });

    },
    async salvarEndereco(endereco){
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getJWTToken()
            },
            body: JSON.stringify(endereco)
        };

        requestOptions.method = endereco.id ? 'PUT' : 'POST'
        let url = '/api/cliente/'+(endereco.id?'atualizar-endereco':'adicionar-endereco');

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+ url, requestOptions)
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }else{
                        reject(response)
                    }

                })
                .then(data => {
                    resolve(data)
                } )
                .catch(err=> reject(err));
        });

    },
    async update (clienteModel){

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getJWTToken()
            },
            body: JSON.stringify(clienteModel)
        };

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+'/api/cliente/update', requestOptions)
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }else{
                        reject(response)
                    }

                })
                .then(data => {

                    data.cliente && setCliente(data.cliente)
                    data.token && setJWTToken(data.token)

                    resolve(data)
                } )
                .catch(err=> reject(err));
        });
    },
    async gerarPedido(pedidoModel){

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getJWTToken()
            },
            body: JSON.stringify(pedidoModel)
        };

        let url = '/api/pedido/gerar-pedido';

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+ url, requestOptions)
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }else{
                        reject(response)
                    }

                })
                .then(data => {
                    resolve(data)
                } )
                .catch(err=> reject(err));
        });

    },
    async cancelarPedido(pedidoModel){

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getJWTToken()
            },
            body: JSON.stringify(pedidoModel)
        };

        let url = '/api/pedido/cancelar-pedido-por-cliente/'+pedidoModel.id;

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+ url, requestOptions)
                .then(response => {
                    if(response.ok){
                        resolve()
                    }else{
                        reject(response)
                    }

                })
                .catch(err=> reject(err));
        });

    },
    async removerComprovantePix(pedidoModel){

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getJWTToken()
            },
            // body: JSON.stringify(pedidoModel)
        };

        let url = '/api/pedido/remover-comprovante-pix/'+pedidoModel.id;

        return new Promise((resolve, reject) => {
            let endpointUrl = getEndpointUrl();
            fetch(endpointUrl+ url, requestOptions)
                .then(response => {
                    if(response.ok){
                        resolve()
                    }else{
                        reject(response)
                    }

                })
                .catch(err=> reject(err));
        });

    }

}

export default ClienteService;

