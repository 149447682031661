import React from "react";
import Layout from "../components/layout";
import CarrinhoService from "../services/carrinho.service";
import {getEndpointUrl, numberToReal, onlyDigitKeyDownEvent} from "../services/utils.service";
import ClienteService from "../services/cliente.service";
import {getCliente, getJWTToken, isLoggedIn} from "../services/auth";
import {getValorFrete} from "../services/cep.service";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import {getMPPubKey} from "../services/shopinfo.service";
import * as CepService from "../services/cep.service";
import {maskCep} from "../components/mask-str";

const CARRINHO_VIEW='CARRINHO'
const PAGAMENTO_VIEW='PAGAMENTO'
const PEDIDO_GERADO_SUCESSO_VIEW='PEDIDO_GERADO_SUCESSO'

class Carrinho extends React.Component {

    state={
        loading:false,
        view:CARRINHO_VIEW,
        enderecoList:[],
        enderecoSelecionado:null,
        formaPagamentoSelecionada:'',
        comentario:'',
        carrinho:{
            valorTotal:0.0,
            frete:0.0,
            ItemCarrinhos:[]
        },
        cliente:{},
        cardForm :{},
        cepInput:''
    }

    constructor(props) {
        super(props);

        this.loadCarrinho = this.loadCarrinho.bind(this)
        this.removerItemCarrinho = this.removerItemCarrinho.bind(this)
        this.updateItemCarrinho = this.updateItemCarrinho.bind(this)
        this.irParaPagamento = this.irParaPagamento.bind(this)
        this.loadEnderecos = this.loadEnderecos.bind(this)
        this.selecionarEnedereco = this.selecionarEnedereco.bind(this)
        this.getValorTotalCarrinho = this.getValorTotalCarrinho.bind(this)
        this.gerarPedido = this.gerarPedido.bind(this)
        this.setCardForm = this.setCardForm.bind(this)
        this.finishPayment = this.finishPayment.bind(this)
        this.searchCepEndereco = this.searchCepEndereco.bind(this)
    }

    componentDidMount() {
        this.loadCarrinho()
        if(isLoggedIn()){
            this.loadEnderecos()

            let cliente = getCliente();

            this.state.cliente = Object.assign({},cliente);
        }



    }

    componentWillUnmount() {
        try {
            this.state.cardForm.unmount()
        } catch (e) {
        }
    }

    loadCarrinho(){
        CarrinhoService.carregarCarrinho().then((carrinho)=>{
            let carrinhoNovo = Object.assign({frete:this.state.carrinho.frete},carrinho);
            this.setState({carrinho:carrinhoNovo})
        })
    }

    removerItemCarrinho(itemCarrinho){

        CarrinhoService.removerItemCarrinho(itemCarrinho.id).then(()=>{
            window.toastr.info('Item removido','',{closeButton : true})
            let carrinho = Object.assign({frete:this.state.carrinho.frete},CarrinhoService.carrinho);
            this.setState({carrinho: carrinho})
        })

    }

    updateItemCarrinho(itemCarrinho,quantidade){
        if(!quantidade){
            itemCarrinho.quantidade = 0
            this.setState({refresh:true})
            return;
        }
        itemCarrinho.quantidade = quantidade

        let ref = this
        CarrinhoService.atualizarItemCarrinho(this.state.carrinho.id,itemCarrinho.id,quantidade).then(()=>{
            this.state.carrinho.valorTotal = CarrinhoService.carrinho.valorTotal
            ref.setState({refresh:true})
        })
    }

    loadEnderecos(){

        if(isLoggedIn()){
            this.setState({loading:true})

            ClienteService.getEnderecos().then(enderecoList=>{
                this.state.enderecoList = enderecoList
                this.state.enderecoSelecionado = this.state.enderecoList[0]
                this.calcularFrete(this.state.enderecoSelecionado.cep)

            }).finally(()=>{
                this.setState({loading:false})
            })
        }
    }

    calcularFrete(cep){

        getValorFrete(cep).then(endereco=>{
            this.state.carrinho.frete = endereco.valorFrete
            this.setState({refresh:true})

        }).catch(err=>{
            console.error(err)
        })
    }

    irParaPagamento(){
        this.setState({view:PAGAMENTO_VIEW})
        setTimeout(()=>{
            try {
                var element = document.querySelector("#voltarLink");
                element.scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"})
            } catch (e) {
                console.error(e)
            }
        })
    }

    selecionarEnedereco(endereco){
        this.setState({enderecoSelecionado:endereco})
        this.calcularFrete(endereco.cep)
    }


    getValorTotalCarrinho(){
        let valorCarrinho = this.state.carrinho.valorTotal;
        let frete = this.state.carrinho.frete;
        let valorTotal = valorCarrinho+ frete
        return valorTotal
    }

    gerarPedido(){

        this.setState({loading:true})

        let pedido = {
            carrinhoId:this.state.carrinho.id,
            comentario:this.state.comentario,
            endereco:this.state.enderecoSelecionado,
            formaPagamento:this.state.formaPagamentoSelecionada
        }

        ClienteService.gerarPedido(pedido).then((pedido)=>{

            /*this.loadCarrinho()
            this.setState({
                loading:false,
                view:PEDIDO_GERADO_SUCESSO_VIEW
            })*/
            this.finishPayment()
        }).catch(err=>{
            window.toastr.error('Erro ao criar pedido','Tente novamente mais tarde')
        })

    }

    setCardForm(){
        const mp = new window.MercadoPago(getMPPubKey());

        document.getElementById("form-checkout__cardholderEmail").value = this.state.cliente.email

        let amount1 = this.state.carrinho.valorTotal + this.state.carrinho.frete;
        this.state.cardForm = mp.cardForm({
            amount: amount1.toFixed(2),
            autoMount: true,
            form: {
                id: "form-checkout",
                cardholderName: {
                    id: "form-checkout__cardholderName",
                    placeholder: "Nome do titular",
                },
                cardholderEmail: {
                    id: "form-checkout__cardholderEmail",
                    placeholder: "E-mail",
                },
                cardNumber: {
                    id: "form-checkout__cardNumber",
                    placeholder: "Nº do cartão",
                },
                cardExpirationMonth: {
                    id: "form-checkout__cardExpirationMonth",
                    placeholder: "MM",
                },
                cardExpirationYear: {
                    id: "form-checkout__cardExpirationYear",
                    placeholder: "AA",
                },
                securityCode: {
                    id: "form-checkout__securityCode",
                    placeholder: "CVC",
                },
                installments: {
                    id: "form-checkout__installments",
                    placeholder: "Preencha os dados acima",
                },
                identificationType: {
                    id: "form-checkout__identificationType",
                },
                identificationNumber: {
                    id: "form-checkout__identificationNumber",
                    placeholder: "Número do documento",
                },
                issuer: {
                    id: "form-checkout__issuer",
                    placeholder: "Issuer",
                },
            },
            callbacks: {
                onFormMounted: error => {
                    if (error)
                        return console.warn("Form Mounted handling error: ", error);
                    console.log("Form mounted");
                },
                onFormUnmounted: error => {
                    if (error) return console.warn('Form Unmounted handling error: ', error)
                    console.log('Form unmounted')
                },
                onSubmit: event => {
                    this.setState({loading:true})
                    event.preventDefault();
                    document.getElementById("loading-message").style.display = "block";

                    const {
                        paymentMethodId,
                        issuerId,
                        cardholderEmail: email,
                        amount,
                        token,
                        installments,
                        identificationNumber,
                        identificationType,
                    } = this.state.cardForm.getCardFormData();

                    this.state.cardForm.createCardToken()

                    let pedido = {
                        carrinhoId:this.state.carrinho.id,
                        comentario:this.state.comentario,
                        endereco:this.state.enderecoSelecionado,
                        formaPagamento:this.state.formaPagamentoSelecionada
                    }

                    let bodyData = {
                        token,
                        issuerId,
                        paymentMethodId,
                        transactionAmount: Number(amount),
                        installments: Number(installments),
                        carrinhoId:this.state.carrinho.id,
                        clienteId:this.state.cliente.id,
                        description: "Compra carrinho id: "+this.state.carrinho.id,
                        payer: {
                            email,
                            identification: {
                                type: identificationType,
                                number: identificationNumber,
                            },
                        },
                        pedido:pedido
                    };

                    fetch(getEndpointUrl()+"/api/pedido/process_payment", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':'JWT '+getJWTToken()
                        },
                        body: JSON.stringify(bodyData),
                    })
                        .then(response => {
                            return response.json();
                        })
                        .then(result => {

                            console.log(result)
                            // { detail: "cc_rejected_other_reason", status: "rejected", id: 1246536810 }
                            if(result.status==='rejected'){
                                let message = null;
                                const messageMap={
                                    'cc_rejected_other_reason':'Erro ao realização transação. Tente novamente mais tarde',
                                    'cc_rejected_call_for_authorize':'Erro ao realização transação. Tente novamente mais tarde',
                                    'cc_rejected_insufficient_amount':'Saldo insuficiente no cartão de crédito',
                                    'cc_rejected_bad_filled_security_code':'Erro no código de segurança do cartão',
                                    'cc_rejected_bad_filled_date':'Data de validade inválida',
                                    'cc_rejected_bad_filled_other':'Verifique o e-mail informado e o nº do documento',
                                }
                                message = messageMap[result.detail];

                                window.toastr.error(message,'Error na realização do pagamento')

                            }else if(result.error_message && result.error_message==='Invalid card_token_id'){ // error_message	"Invalid card_token_id"

                                // this.remountForm()
                            }else if(result.status==='approved'){
                                // result.pedidoId
                                this.finishPayment();

                            }
                            //ERROR HANDLER BLOCK
                          /*  if(!result.hasOwnProperty("error_message")) {
                                document.getElementById("success-response").style.display = "block";
                                document.getElementById("payment-id").innerText = result.id;
                                document.getElementById("payment-status").innerText = result.status;
                                document.getElementById("payment-detail").innerText = result.detail;
                            } else {
                                document.getElementById("error-message").textContent = result.error_message;
                                document.getElementById("fail-response").style.display = "block";
                            }*/

                        })
                        .catch(error => {
                            alert("Unexpected error\n"+JSON.stringify(error));
                        }).finally(()=>{
                            this.setState({loading:false})
                    });
                },
                onFetching: (resource) => {
                    console.log("Fetching resource: ", resource);
                    const payButton = document.getElementById("form-checkout__submit");
                    payButton.setAttribute('disabled', true);
                    return () => {
                        payButton.removeAttribute("disabled");
                    };
                },
            },
        });

        this.setState({formaPagamentoSelecionada: 'cartaoCredito'})
    }

    finishPayment() {
        this.loadCarrinho()
        this.setState({
            loading: false,
            view: PEDIDO_GERADO_SUCESSO_VIEW
        })
        window.scrollTo(0, 0);
    }

    searchCepEndereco(event){

        if(this.state.cepInput.replace(/\D/g,'').length===8){
            CepService.loadCep(this.state.cepInput).then(enderecoViaCep=>{

                /*
                "cep": "66813-750",
                  "logradouro": "Travessa N-5",
                  "complemento": "(Cj COHAB)",
                  "bairro": "Campina de Icoaraci (Icoaraci)",
                  "localidade": "Belém",
                  "uf": "PA",
                  "ibge": "1501402",
                  "gia": "",
                  "ddd": "91",
                  "siafi": "0427"
                 */

                let endereco = {
                    logradouro : enderecoViaCep.logradouro,
                    complemento : enderecoViaCep.complemento,
                    bairro : enderecoViaCep.bairro,
                    localidade : enderecoViaCep.localidade,
                    uf : enderecoViaCep.uf,
                }
                this.calcularFrete(this.state.cepInput)

            }).catch(err=>{
                console.error(err)
            }).finally(()=>{
                this.setState({
                    refresh: true
                });
            })
        }



    }

    render() {
        return (
            <Layout>
                <div className="product-area ptb-90">
                    <div className="container">

                        <div className={(this.state.view===CARRINHO_VIEW?'':'hide')+' row'}>
                            <div className="col-sm-12">
                                <div className="cart-with-coupon">
                                    <div className="table-responsive">
                                        <table className="table cart-table">
                                            <thead>
                                            <tr>
                                                <th className="col-remove"></th>
                                                <th className="col-thumbnail"></th>
                                                <th className="col-title">Produto</th>
                                                <th className="col-price">Preço</th>
                                                <th className="col-quantity">Quantidade</th>
                                                <th className="col-subtotal">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.carrinho.ItemCarrinhos && this.state.carrinho.ItemCarrinhos.length === 0 && (
                                                <tr>
                                                    <td colSpan="6" className='text-center'>
                                                        <p><strong>
                                                            Carrinho vazio
                                                        </strong></p>
                                                    </td>
                                                </tr>
                                            )}

                                            {this.state.carrinho.ItemCarrinhos && this.state.carrinho.ItemCarrinhos.length > 0 &&

                                                this.state.carrinho.ItemCarrinhos.map((itemCarrinho,idx) => (
                                                    <tr key={idx}>
                                                        <td className="col-trash">
                                                            <a className='clickable'
                                                               onClick={() => this.removerItemCarrinho(itemCarrinho)}>
                                                                <i className="fa fa-trash text-danger"
                                                                   aria-hidden="true"/></a>
                                                        </td>
                                                        <td className="col-thumbnail">
                                                            <a >
                                                                <img className="cart-thumbnail"
                                                                     src={itemCarrinho.ProdutoEstoque.imagemPrincipal.lowUrl}
                                                                     alt=""/></a>
                                                        </td>
                                                        <td className="col-title">
                                                            <h5 className="m-b-5">
                                                                <a >{itemCarrinho.ProdutoEstoque.Produto.nome}</a>
                                                            </h5>
                                                            <span className="text-xs">
                                                                <div>
                                                                    <div>
                                                                        Cor:<div
                                                                        className=' badge user-select-none m-15 clickable border-1px-black'
                                                                        style={{backgroundColor: itemCarrinho.ProdutoEstoque.ProdutoCor.hexColor}}
                                                                        title={itemCarrinho.ProdutoEstoque.ProdutoCor.nome}>	&nbsp;    &nbsp; </div>
                                                                        {itemCarrinho.ProdutoEstoque.tamanho && (
                                                                            <span>
                                                                            Tamanho: {itemCarrinho.ProdutoEstoque.tamanho}
                                                                        </span>
                                                                        )}
                                                                        {itemCarrinho.ProdutoEstoque.membro && (
                                                                            <span>
                                                                            Membro: {itemCarrinho.ProdutoEstoque.membro}
                                                                        </span>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </td>
                                                        <td className="col-price">
                                                            R$ {numberToReal(itemCarrinho.ProdutoEstoque.Produto.preco)}
                                                        </td>
                                                        <td className={((itemCarrinho.quantidade > itemCarrinho.ProdutoEstoque.quantidade) ? 'has-error' : '') + ' col-quantity'}>
                                                            <input type="number" className="form-control" step="1"
                                                                   min="1" onChange={(event) => {
                                                                this.updateItemCarrinho(itemCarrinho, event.target.value)
                                                            }} value={itemCarrinho.quantidade}
                                                                   max={itemCarrinho.ProdutoEstoque.quantidade}/>
                                                        </td>
                                                        <td className="col-subtotal">
                                                            R$ {numberToReal(itemCarrinho.ProdutoEstoque.Produto.preco * itemCarrinho.quantidade)}
                                                        </td>
                                                    </tr>

                                                ))
                                            }

                                            </tbody>
                                        </table>
                                    </div>

                                    {this.state.carrinho.ItemCarrinhos && this.state.carrinho.ItemCarrinhos.length > 0 && (
                                        <>
                                            <div className="totals">
                                                <table className="table totals-table">
                                                    <tbody>
                                                    <tr className='hide'>
                                                        <td><h5 className="m-0">Sub total</h5></td>
                                                        <td></td>
                                                        <td><h5 className="m-0 color-gray text-right">$702.00</h5></td>
                                                    </tr>
                                                    <tr className=''>
                                                        <td><h5 className="m-0">Frete</h5></td>
                                                        <td>
                                                            <div>
                                                                <label >Calcular frete</label>
                                                                <input type="text" className='form-control w-40' placeholder='Informe o CEP' onKeyDown={onlyDigitKeyDownEvent}
                                                                       value={this.state.cepInput}
                                                                       onChange={(event)=> this.setState({cepInput:maskCep(event.target.value)}) }
                                                                />
                                                                <button className='btn btn-sm btn-default' onClick={()=> this.searchCepEndereco()}>pesquisar</button>
                                                            </div>

                                                        </td>
                                                        <td><h5 className="m-0 color-gray text-right">R$ {numberToReal(this.state.carrinho.frete)}</h5></td>
                                                    </tr>
                                                    <tr>
                                                        <td><h4 className="m-0">Total</h4></td>
                                                        <td></td>
                                                        <td>
                                                            <h4 className="m-0 text-right">
                                                                R$ {numberToReal(this.getValorTotalCarrinho())}
                                                            </h4>
                                                        </td>
                                                    </tr>
                                                    {
                                                        isLoggedIn()==false && (
                                                            <tr className='text-center'>
                                                                <td colSpan={3}>
                                                                    Para finalizar seu pedido, <Link to="/registrar">realize seu cadastro aqui</Link>
                                                                </td>

                                                            </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    )}

                                    <div className="hide coupon">
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control"
                                                       placeholder="Coupon Code"/>
                                            </div>
                                            <div className="col-sm-2">
                                                <a className="btn btn-block btn-round btn-dark m-t-xs-20">Apply</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div className={( (this.state.enderecoList && this.state.enderecoList.length==0)?'hide':'')+' row mt-40'}>
                                        <h4>Endereço de entrega</h4>
                                        <hr/>

                                        { this.state.enderecoList && this.state.enderecoList.length>0 && this.state.enderecoList.map((endereco,idx)=>(
                                                (
                                                    <div className="col-md-4" key={idx}>
                                                        <div className="panel panel-default">
                                                            <div className="panel-body">
                                                                <div>
                                                                    <input type="radio" name='enderecoRadio' value={endereco.id} checked={this.state.enderecoSelecionado && this.state.enderecoSelecionado.id===endereco.id} onChange={()=> this.selecionarEnedereco(endereco) } />
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <strong>{endereco.apelido}</strong>
                                                                    </div>
                                                                    <div>{endereco.logradouro}, {endereco.numero}</div>
                                                                    <div>Bairro: {endereco.bairro}, {endereco.localidade}-{endereco.uf}</div>
                                                                    <div>cep:{endereco.cep}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            ))
                                        }

                                    </div>

                                </div>


                                {isLoggedIn() && this.state.carrinho.ItemCarrinhos && this.state.carrinho.ItemCarrinhos.length > 0 && (
                                    <>
                                        <div className='row mt-40'>
                                            <h4>Comentário</h4>
                                            <hr/>

                                            <div className="payment m-b-70 col-sm-4">
                                                <textarea name="comentario" id="" cols="60" rows="5" value={this.state.comentario} onChange={(event)=>this.setState({comentario:event.target.value})} />
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 text-right">
                                                <a className="clickable btn btn-round btn-lg btn-success"
                                                   onClick={() => this.irParaPagamento()}>Ir para pagamento</a>
                                            </div>
                                        </div>
                                    </>

                                )}



                            </div>
                        </div>

                        <div className={(this.state.view===PAGAMENTO_VIEW?'':'hide')+' row'} >
                            <div className="col-sm-12 text-center">
                                <a id='voltarLink' className='clickable' onClick={()=>this.setState({view:CARRINHO_VIEW})}>voltar</a>
                            </div>

                            <div className="col-sm-12">
                                <div className='row'>
                                    <h4>Pedido</h4>
                                    <hr/>

                                    <table className='table table-bordered w-70'>

                                        <tbody>
                                        <tr>
                                            <td>
                                                <strong>PRODUTO</strong>
                                            </td>
                                            <td>
                                                <strong>
                                                    QUANTIDADE
                                                </strong>
                                            </td>
                                            <td>
                                                <strong>PREÇO</strong>
                                            </td>
                                        </tr>



                                        {
                                            this.state.carrinho.ItemCarrinhos.map((itemCarrinho,idx)=>(

                                                <tr key={idx}>
                                                    <td>
                                                        <p>
                                                            {itemCarrinho.ProdutoEstoque.Produto.nome}
                                                            <small className='ml-10'>
                                                                {itemCarrinho.ProdutoEstoque.tamanho &&
                                                                    <span className='ml-10'>Tam.: {itemCarrinho.ProdutoEstoque.tamanho}</span>}

                                                                {itemCarrinho.ProdutoEstoque.membro && <span className='ml-10'>
                                                                    Membro: {itemCarrinho.ProdutoEstoque.membro} </span>}

                                                                {itemCarrinho.ProdutoEstoque.ProdutoCor && <span className='ml-10'>
                                                                    Cor: {itemCarrinho.ProdutoEstoque.ProdutoCor.nome} </span>}


                                                            </small>
                                                        </p>

                                                    </td>
                                                    <td>
                                                        {itemCarrinho.quantidade}
                                                    </td>
                                                    <td>
                                                        R$ {numberToReal(itemCarrinho.quantidade*itemCarrinho.ProdutoEstoque.Produto.preco)}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td className='bg-info' colSpan={4}>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Frete:</td>
                                            <td>
                                            </td>
                                            <td>
                                                <div >
                                                    <p> R$ {numberToReal(this.state.carrinho.frete)}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total:</td>
                                            <td></td>
                                            <td>
                                                <div >
                                                    <p> R$ {numberToReal(this.state.carrinho.valorTotal + this.state.carrinho.frete)}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>



                                </div>


                                <div className='row mt-40'>
                                    <h4>Endereço de entrega</h4>
                                    <hr/>
                                    {this.state.enderecoSelecionado && (
                                        <div className="col-md-4">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div>
                                                        <div>
                                                            <strong>{this.state.enderecoSelecionado.apelido}</strong>
                                                        </div>
                                                        <div>{this.state.enderecoSelecionado.logradouro}, {this.state.enderecoSelecionado.numero}</div>
                                                        <div>Bairro: {this.state.enderecoSelecionado.bairro}, {this.state.enderecoSelecionado.localidade}-{this.state.enderecoSelecionado.uf}</div>
                                                        <div>cep:{this.state.enderecoSelecionado.cep}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>

                                <div className='row mt-40'>
                                    <h4>Forma de pagamento</h4>
                                    <hr/>


                                    <div className='row'>
                                        <div className="payment m-b-70 col-sm-4 col-md-4 col-lg-4">
                                            <div className=''>
                                                <table className="table payment-table">
                                                    <tbody>
                                                    <tr>
                                                        <td className={this.state.formaPagamentoSelecionada==='pix'?'bg-info':null}>
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="radio" name="formaPagamentoRadio" value="pix" checked={this.state.formaPagamentoSelecionada==='pix'} onChange={()=>this.setState({formaPagamentoSelecionada:'pix'})} />
                                                                    <span className="ddd">PIX</span>
                                                                    <figure className="figure">
                                                                        <StaticImage
                                                                            src="../images/pix-logo.png"
                                                                            quality={95}
                                                                            formats={["auto", "webp", "avif"]}
                                                                            alt="Pix logo"
                                                                            style={{ marginBottom: `1.45rem` }}
                                                                        />
                                                                    </figure>

                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr className={this.state.formaPagamentoSelecionada==='cartaoCredito'?'bg-info':null}>
                                                        <td>
                                                            <div className="radio">
                                                                <label>
                                                                    <input type="radio" name="formaPagamentoRadio" value="cartaoCredito" checked={this.state.formaPagamentoSelecionada==='cartaoCredito'} onChange={()=> {
                                                                        this.setCardForm()
                                                                    } } />
                                                                    <span className="ddd">Cartão de crédito</span>
                                                                    <figure className="figure">
                                                                        <StaticImage
                                                                            src="../images/cartoes-credito.png"
                                                                            quality={95}
                                                                            formats={["auto", "webp", "avif"]}
                                                                            alt="Bandeira de cartões de crédito"
                                                                            style={{ marginBottom: `1.45rem` }}
                                                                        />
                                                                    </figure>
                                                                    <span>
                                                                                <small>
                                                                                    Fique tranquilo! Nossos pagamentos são processados pela <a href='https://www.mercadopago.com.br' target='_blank'>mercado pago</a>.
                                                                                </small>
                                                                            </span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="payment ">
                                            <div className={this.state.formaPagamentoSelecionada==='cartaoCredito'?'':'hide'}>
                                                <div className="col-md-6 col-md-6 col-xs-12">

                                                    <div className="panel panel-default credit-card-box">
                                                        <div className="panel-heading display-table">
                                                            <div className="row display-tr text-center">
                                                                <h3 className="panel-title display-td">Pagamento com cartão de cŕedito</h3>
                                                            </div>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="payment-details">
                                                                <form id="form-checkout">
                                                                    <strong className="title">Detalhes do comprador</strong>
                                                                    <div className="row">
                                                                        <div className="form-group col">
                                                                            <input
                                                                                id="form-checkout__cardholderEmail"
                                                                                name="cardholderEmail"
                                                                                type="email"
                                                                                className="form-control"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="form-group col-sm-5">
                                                                            <select
                                                                                id="form-checkout__identificationType"
                                                                                name="identificationType"
                                                                                required
                                                                                className="form-control"></select>
                                                                        </div>
                                                                        <div className="form-group col-sm-7">
                                                                            <input
                                                                                id="form-checkout__identificationNumber"
                                                                                onKeyDown={onlyDigitKeyDownEvent}
                                                                                name="docNumber" type="text"
                                                                                required
                                                                                className="form-control"/>
                                                                        </div>
                                                                    </div>
                                                                    <br/>
                                                                    <strong className="title">Dados do cartão de crédito</strong>
                                                                    <div className="row">
                                                                        <div className="form-group col-sm-8">
                                                                            <label>Nome do titular</label>
                                                                            <input
                                                                                id="form-checkout__cardholderName"
                                                                                name="cardholderName"
                                                                                type="text"
                                                                                required
                                                                                className="form-control"/>
                                                                        </div>
                                                                        <div
                                                                            className="form-group col-sm-4">
                                                                            <div className="row input-group expiration-date">
                                                                                <label >Mês/Ano vencimento</label>
                                                                                <input
                                                                                    id="form-checkout__cardExpirationMonth"
                                                                                    name="cardExpirationMonth"
                                                                                    type="text"
                                                                                    maxLength={2}
                                                                                    required
                                                                                    onKeyDown={onlyDigitKeyDownEvent}
                                                                                    className="form-control"
                                                                                    style={{width:'55px'}}
                                                                                />
                                                                                <input
                                                                                    id="form-checkout__cardExpirationYear"
                                                                                    name="cardExpirationYear"
                                                                                    type="text"
                                                                                    maxLength={2}
                                                                                    required
                                                                                    style={{width:'55px'}}
                                                                                    onKeyDown={onlyDigitKeyDownEvent}
                                                                                    className="form-control"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col-sm-8">
                                                                            <label>Nº do cartão de crédito</label>
                                                                            <input
                                                                                id="form-checkout__cardNumber"
                                                                                name="cardNumber"
                                                                                type="text"
                                                                                required
                                                                                onKeyDown={onlyDigitKeyDownEvent}
                                                                                className="form-control"/>
                                                                        </div>
                                                                        <div className="form-group col-sm-4">
                                                                            <label>CVC</label>
                                                                            <input
                                                                                id="form-checkout__securityCode"
                                                                                name="securityCode"
                                                                                type="text"
                                                                                required
                                                                                onKeyDown={onlyDigitKeyDownEvent}
                                                                                className="form-control"/>
                                                                        </div>
                                                                        <div id="issuerInput" className="form-group col-sm-12 hidden">
                                                                            <label >Qantidade de parcelas</label>
                                                                            <select
                                                                                id="form-checkout__issuer"
                                                                                name="issuer"
                                                                                required
                                                                                className="form-control"></select>
                                                                        </div>
                                                                        <div
                                                                            className="form-group col-sm-12">
                                                                            <select
                                                                                id="form-checkout__installments"
                                                                                name="installments"
                                                                                type="text"
                                                                                className="form-control"></select>
                                                                        </div>
                                                                        <div
                                                                            className="form-group col-sm-12">
                                                                            <input type="hidden"
                                                                                   id="amount"/>
                                                                            <input type="hidden"
                                                                                   id="description"/>
                                                                            <br/>
                                                                            <button
                                                                                id="form-checkout__submit"
                                                                                type="submit"
                                                                                disabled={this.state.loading}
                                                                                className="btn btn-primary btn-block">REALIZAR PAGAMENTO
                                                                            </button>
                                                                            <br/>
                                                                            <p className={((this.state.loading)?'':'hide')+' text-center'} id="loading-message">Realizando transação, por favor aguarde...

                                                                                <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>


                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                            <div className={this.state.formaPagamentoSelecionada==='pix'?'':'hide'}>
                                                <div className='col-xs-12 col-md-4 mt-40'>
                                                    <div>
                                                        <p>
                                                            Para pagamentos via <strong>PIX</strong>, realize um transferência no valor total do seu pedido de R$ {numberToReal(this.state.carrinho.valorTotal + this.state.carrinho.frete)}.
                                                        </p>
                                                        <p>
                                                            Os dados para pagamento via <strong>PIX</strong>, serão exibidos após a geração do pedido.
                                                        </p>
                                                    </div>
                                                    <div className='bg-warning'>
                                                        <strong>OBS: Pedidos gerados e não confirmados, serão cancelados automáticamente pelo nosso sistema após 24h.</strong>
                                                    </div>

                                                    <div className='text-center mt-40'>
                                                        <button className='btn btn-success' onClick={()=>{this.gerarPedido()}}>
                                                            Gerar pedido
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {/*  <div >
                                            <div className="col-sm-12 text-center">
                                                <button disabled={this.state.loading} className="btn btn-round btn-lg btn-success" onClick={this.gerarPedido}>Gerar pedido</button>
                                                {this.state.loading?(
                                                    <div className="mt-30 text-center">
                                                        <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                                    </div>
                                                ):null}

                                            </div>
                                            <div className="col-sm-12 text-center mt-20">
                                                <a className='clickable' onClick={()=>this.setState({view:CARRINHO_VIEW})}>voltar</a>
                                            </div>
                                        </div>*/}

                            </div>
                        </div>

                        <div className={(this.state.view===PEDIDO_GERADO_SUCESSO_VIEW?'':'hide')+' row'} >
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center alert alert-success">
                                        <h2 className='text-success'>PEDIDO CRIADO COM SUCESSO!!</h2>
                                    </div>
                                    {this.state.formaPagamentoSelecionada==='pix' && (
                                     <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <p>Seu pedido foi gerado em nosso sistema. </p>
                                            <p>Para realizar o pagamento via pix, realize uma transferência para os dados abaixo no valor de <strong> R$ {numberToReal(this.state.carrinho.valorTotal + this.state.carrinho.frete)} </strong> </p>
                                            <div >
                                                <div className="col-md-6" >
                                                    <div className="panel panel-default">
                                                        <div className="panel-body">
                                                            <div>
                                                                <h3>CNPJ: 88.342.580/0001-46</h3>
                                                                <h4>Nome: Orthonet LTDA</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                             <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                 <p>Após o pagamento, <Link to='/app/meus-pedidos'>verifique seus pedidos</Link> na sua área de usuário e
                                                     <strong> anexe o comprovante ao seu pedido</strong>.</p>
                                             </div>
                                        </div>
                                    )}

                            {this.state.formaPagamentoSelecionada==='cartaoCredito' && (
                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                    <p>
                                        <Link to='/app/meus-pedidos'>Verifique seus pedidos</Link> na sua área de usuário
                                        para acompanhar o andamento de seu pedido.
                                    </p>

                                </div>

                                )}

                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <p className='text-danger'>
                                            <strong>ATENÇÃO</strong></p>
                                        <p>
                                            <strong>Pedidos gerados a mais de 1 dia são cancelados automáticamente pelo nosso sistema.</strong>
                                        </p>
                                    </div>

                                </div>

                    </div>
                </div>
            </Layout>

        );
    }

}

export default Carrinho
